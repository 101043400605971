<template>
  <div class="q-py-md" style="max-width: 1980px;">
    <div class="row full-width" style="min-height: inherit;">
      <div class="col-6 q-px-xl">
        <div class="row full-width q-my-md justify-center">
          <q-btn color="primary" @click="findUser = true">New Message</q-btn>
        </div>
        <ThreadList v-if="threads.length > 0" :threads="threads" @selectThread="selectThread" />
        <div v-else>
          <div class="text-subtitle1 text-center">You don't have any messages</div>
        </div>
      </div>
      <Chat :opened="openThread" :thread="selectedThread" @updateThread="updateThread" @close="openThread = false" />
    </div>

    <q-dialog v-model="showModal" @hide="resetNewMessage">
      <q-card style="width: 560px; max-width: 560px" class="bg-lmsBackground primaryText-text">
        <q-form @submit="sendMessage" @reset="showModal = false" autocomplete="off">
          <q-card-section>
            <div class="col-xs-12 q-mb-lg text-body1">
              Send to: <div class="text-bold">{{ selectedUser.label }}</div>
            </div>
            <div class="col-xs-12 q-mb-lg">
              <q-input v-model="newMessage.subject" label="Subject" />
            </div>
            <div class="col-xs-12">
              <q-editor v-model="newMessage.body" :value="newMessage.body" label="Message" min-height="5rem" max-height="10rem" content-class="bg-lmsBackground" toolbar-bg="lmsHeader" toolbar-text-color="white" toolbar-toggle-color="primaryText" />
            </div>
          </q-card-section>
          <div class="row gutter-xs q-my-lg q-mx-lg">
            <div class="col-12">
              <q-btn type="submit" class="float-right" :loading="sending" color="primary">Send</q-btn>
              <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
            </div>
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <q-dialog v-model="findUser">
      <UserSearch @userSelected="userSelected" @closeForm="findUser = false"/>
    </q-dialog>
  </div>
</template>

<script>
import ThreadList from 'components/messages/ThreadList'
import Chat from 'components/messages/Chat'
import UserSearch from 'components/UserSearch'
export default {
  components: { ThreadList, Chat, UserSearch },
  data () {
    return {
      findUser: false,
      threads: [],
      selectedThread: null,
      showModal: false,
      newMessage: {
        recipient_uid: null,
        subject: '',
        body: ''
      },
      sending: false,
      openThread: false,
      selectedUser: {}
    }
  },
  methods: {
    resetNewMessage () {
      this.newMessage = {
        recipient_uid: null,
        subject: '',
        body: ''
      }
    },
    async sendMessage () {
      this.sending = true
      try {
        let res = await this.$axios.post(this.$consts.ADD_MESSAGE_URL, this.newMessage)
        this.sending = false
        this.$successMsg()
        res.data.lastMessageDate = res.data.created_at
        this.threads.push(res.data)
        this.selectThread(res.data.uid)
        this.showModal = false
      } catch (e) {
        this.sending = false
        this.$failureMsg()
      }
    },
    updateThread (newReply) {
      this.selectedThread.messages.push(newReply)
      this.selectedThread.lastMessageDate = newReply.created_at
    },
    selectThread (threadUid) {
      this.openThread = false
      let selectedThread = this.threads.find(thread => thread.uid === threadUid)
      this.selectedThread = selectedThread
      this.openThread = true
    },
    userSelected (user) {
      this.selectedUser = user
      this.newMessage.recipient_uid = user.value
      this.findUser = false
      this.showModal = true
    }
  },
  async created () {
    this.$q.loading.show()
    let res = await this.$axios.get(this.$consts.GET_MESSAGES_URL)
    res.data.forEach(thread => {
      thread.lastMessageDate = thread.messages.slice(-1)[0].created_at
    })
    this.threads = res.data.filter(thread => {
      return thread.author && thread.recipient
    })
    this.$q.loading.hide()
  }
}
</script>
