<template>
  <q-card class="q-pa-md bg-lmsBackground" flat>
    <q-list bordered separator>
      <q-item v-for="thread in currentThreads" :key="thread.parent_uid" class="q-py-md" :active="selectedThread === thread.parent_uid" active-class="bg-info primaryText-text" clickable v-ripple @click="selectThread(thread)">
        <q-item-section avatar>
          <q-avatar>
            <q-img :src="getOtherPerson(thread, 'avatar') || $consts.DEFAULT_AVATAR" />
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label>{{ getOtherPerson(thread, 'name') }} - {{ thread.subject }}</q-item-label>
          <q-item-label caption lines="1"><div class="text-italic secondaryText-text">{{ getLast(thread, 'message') }}</div></q-item-label>
        </q-item-section>

        <q-item-section side>
          <q-item-label>{{ getLast(thread, 'date') }}</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-item-label v-if="!hasRead(thread)">
            <q-chip icon="mail" color="primary" text-color="white" dense>Unread</q-chip>
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="row full-width justify-center q-mt-lg">
      <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
    </div>
  </q-card>
</template>

<script>
import { date } from 'quasar'

export default {
  props: ['threads'],
  data () {
    return {
      selectedThread: null,
      page: 1,
      perPage: 10
    }
  },
  computed: {
    sortedThreads () {
      let sortedThreads = [ ...this.threads ]
      return sortedThreads.sort((a, b) => {
        return date.formatDate(b.lastMessageDate, 'X') - date.formatDate(a.lastMessageDate, 'X')
      })
    },
    pageMax () {
      if (this.sortedThreads.length === 0) return 0
      return Math.ceil(this.sortedThreads.length / this.perPage)
    },
    currentThreads () {
      let startIndex = (this.page - 1) * this.perPage
      return this.sortedThreads.slice(startIndex, startIndex + this.perPage)
    }
  },
  methods: {
    getOtherPerson (thread, prop) {
      return thread.author.uid === this.$store.state.auth.user.uid ? thread.recipient[prop] : thread.author[prop]
    },
    getLast (thread, prop) {
      return prop === 'date' ? this.formatLastDate(thread.messages.slice(-1)[0].created_at) : this.formatPreviewText(thread.messages.slice(-1)[0].body)
    },
    hasRead (thread) {
      return thread.messages.slice(-1)[0].recipient_uid === this.$store.state.auth.user.uid ? thread.messages.slice(-1)[0].hasRead : true
    },
    formatLastDate (msgDate) {
      let today = Date.now()

      if (date.isSameDate(today, msgDate, 'year') && date.isSameDate(today, msgDate, 'month') && date.isSameDate(today, msgDate, 'day')) {
        return date.formatDate(new Date(msgDate + ' UTC'), 'hh:mm a')
      } else if (date.getDateDiff(today, msgDate, 'days') < 7) {
        return date.formatDate(new Date(msgDate + ' UTC'), 'dddd')
      } else {
        return date.formatDate(new Date(msgDate + ' UTC'), 'MM/DD/YYYY')
      }
    },
    formatPreviewText (msg) {
      var doc = new DOMParser().parseFromString(msg, 'text/html')
      return doc.body.textContent || ''
    },
    async selectThread (thread) {
      this.$emit('selectThread', thread.uid)
      this.selectedThread = thread.uid
      if (!thread.messages.slice(-1)[0].hasRead) {
        thread.messages.slice(-1)[0].hasRead = true

        try {
          await this.$axios.post(this.$consts.READ_MESSAGE_URL, { parent_uid: thread.uid })
        } catch (e) {
          throw e
        }
      }
    }
  }
}
</script>
